export enum ItemKinds {
  AUTO_INSURANCE = 'auto_insurance',
  DIY_MOVE = 'diy_move',
  DMV = 'dmv',
  FORWARD_MAIL = 'forward_mail',
  UPDATE_ACCOUNT = 'update_account',
  SHARE = 'share',
  HOME_SECURITY = 'home_security',
  HOME_SERVICE = 'home_service',
  HUDSON_INSURANCE = 'hudson_insurance',
  INSURANCE = 'insurance',
  INSURANCE_QUOTE = 'insurance_quote',
  INSURANCE_RETENTION = 'insurance_retention',
  MOVEIQ = 'moveiq',
  MOVING_CENTER = 'moving_center',
  OFFER = 'offer',
  PROMOTION = 'promotion',
  RECOMMENDED_PROVIDER = 'recommended_provider',
  RED_CROSS = 'red_cross',
  RENTERS_INSURANCE = 'renters_insurance',
  HOMEOWNER_INSURANCE = 'homeowner_insurance',
  RESIDENT_PORTAL = 'resident_portal',
  SMART_HOME_OWN = 'smart_home_own',
  TV_INTERNET = 'tv_internet',
  UTILITY = 'utility',
  CALIFORNIA_CARE = 'california_care',
  BACKUP_GENERATOR = 'backup_generator',
  DUKE_WARRANTY = 'duke_warranty',
  DOLLY = 'dolly',
  MOVE_YOUR_STUFF = 'move_your_stuff',
  RELOCATION_SPECIALISTS = 'relocation_specialists',
  PACKING_SUPPLIES = 'packing_supplies',
  COMBINED_FSM_MYS = 'combined_fsm_mys',
  RESIDENT_ONBOARDING = 'resident_onboarding',
  RESIDENT_ONBOARDING_INSURANCE = 'resident_onboarding-renters-insurance',
  RESIDENT_ONBOARDING_PET_REGISTRATION = 'resident_onboarding-pets',
  RESIDENT_ONBOARDING_MOVE_IN_COSTS = 'resident_onboarding-move-costs',
  RESIDENT_ONBOARDING_PROOF_OF_ELECTRIC = 'resident_onboarding-proof-of-electric',
  RESIDENT_ONBOARDING_PROOF_OF_GAS = 'resident_onboarding-proof-of-gas',
  RESIDENT_ONBOARDING_PROOF_OF_INSURANCE = 'resident_onboarding-proof-of-insurance',
  RESIDENT_ONBOARDING_PROOF_OF_WATER = 'resident_onboarding-proof-of-water',
  RESIDENT_ONBOARDING_VEHICLE_REGISTRATION = 'resident_onboarding-vehicles',
  RESIDENT_ONBOARDING_VEHICLE_REGISTRATION_HOSTED = 'resident_onboarding-vehicles-hosted',
  RESIDENT_ONBOARDING_RESIDENT_LINK = 'resident_onboarding-resident-link',
  RESIDENT_ONBOARDING_DOCUMENT_REVIEW = 'resident_onboarding-document-review',
  RESIDENT_ONBOARDING_SECURITY_DEPOSIT_ALTERNATIVE = 'resident_onboarding-security-deposit-alternative',
  RESIDENT_ONBOARDING_DOWNLOAD_COMMUNITY_APP = 'resident_onboarding-download-community-app',
  RESIDENT_ONBOARDING_LOADING_DOCK_RESERVATION = 'resident_onboarding-loading-dock-reservation',
  RESIDENT_ONBOARDING_ELEVATOR_SCHEDULING = 'resident_onboarding-elevator-scheduling',
  RESIDENT_ONBOARDING_KEY_PICKUP = 'resident_onboarding-key-pickup',
  RESIDENT_ONBOARDING_PACKAGES_AND_DELIVERIES = 'resident_onboarding-packages-and-deliveries',
}
