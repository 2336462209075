import {
  OldSubmission,
  PetsInformantSubmission,
  PetsStructuredSubmit,
  StringBool,
  Submission,
} from '../constants/types';

// for submission translation:
type AgeRange = '0-6' | '7+';
type Weight = 'small' | 'large';

export const getRangeArray = (
  start: number,
  end: number | undefined
): number[] => {
  if (end) {
    return Array(end - start + 1)
      .fill(1)
      .map((_, idx) => start + idx);
  }

  return [];
};
export const stringToBool = (str: string | undefined): boolean | undefined => {
  if (typeof str === 'undefined') return undefined;
  /* TODO: these edge case handlers can be removed once we remove old resOnboarding code from repo
  /** prob not needed, but for edgecase if user
   * some how gets into old res pets flow and submits
   * after a user submitted in updated flow w structuredSubmit answers
   * */
  return typeof str === 'boolean' ? str : str === 'true';
};

export const boolToString = (
  value: boolean | undefined
): StringBool | undefined => {
  if (typeof value === 'undefined') return undefined;
  return value ? 'true' : 'false';
};

export const getKeyName = (
  keyName: string,
  currentPetNumber: number,
  formatForSubmission = false
): string => {
  if (currentPetNumber === 1) {
    return keyName;
  }
  /** old submission are submitted with - between key and petNum
   * submissions are retuned with underscores _ between key and petNum */
  return formatForSubmission
    ? `${keyName}-${currentPetNumber}`
    : `${keyName}_${currentPetNumber}`;
};

const getAgeFromString = (age?: AgeRange): number | undefined => {
  if (!age) return undefined;
  // transforms old subissions that were string ranges to numbers
  return age === '0-6' ? 6 : 7;
};
const getWeightFromString = (weight?: Weight): number | undefined => {
  if (!weight) return undefined;
  // transforms old subissions that were strings to numbers
  return weight === 'small' ? 15 : 16;
};

const formatToOriginalSubmission = (submission: PetsStructuredSubmit[]) => {
  return submission.reduce((acc, values, idx) => {
    // add 1 to index since items api counts pets from 1 not 0 e.g. if 2 pets, keys are: hasPet, hasPet2
    const petNum = idx + 1;
    return {
      ...acc,
      [getKeyName(`has-pet`, petNum, true)]: { value: values.has_pet },
      [getKeyName('cat-or-dog', petNum, true)]: { value: values.pet_type },
      [getKeyName('pet-age', petNum, true)]: { value: values.age },
      [getKeyName('pet-weight', petNum, true)]: { value: values.weight },
      [getKeyName('pet-photo', petNum, true)]: { files: values.photo_files },
      [getKeyName('esa-animal', petNum, true)]: {
        value: values.is_esa,
      },
      [getKeyName('pet-vax', petNum, true)]: { files: values.vax_files },
      [getKeyName('pet-name', petNum, true)]: { value: values.name },
      [getKeyName('pet-breed', petNum, true)]: { value: values.breed },
      [getKeyName('esa-files', petNum, true)]: { files: values.esa_files },
    };
  }, {});
};

export const formatPetsSubmission = (submission: PetsStructuredSubmit[]) => {
  return {
    ...formatToOriginalSubmission(submission),
    structured_submit: submission,
  };
};

export const loadPreviousPetsSubmission = (
  submission?: Submission | OldSubmission,
  structuredSubmit?: PetsStructuredSubmit[],
  maxPetsAllowed?: number
): Array<PetsStructuredSubmit> => {
  if (submission === undefined && structuredSubmit === undefined) {
    return [];
  }
  // TODO: these edge case handlers can be removed once we remove old resOnboarding code from repo
  // handle edgecase (prob can remove in future) for if user goes to old flow and submits
  if (structuredSubmit && Object.keys(structuredSubmit).length !== 0) {
    return structuredSubmit;
  }

  const generateSubmittedDataKey = (
    key: string,
    num: number
  ): string | string[] | undefined | boolean => {
    if (!submission) {
      return undefined;
    }
    return (
      submission[getKeyName(key, num)]?.files ||
      submission[getKeyName(key, num)]?.value
    );
  };

  const maxNumberOfPetsArray = getRangeArray(1, maxPetsAllowed);
  // we use this function only for old submitted data
  const structuredOldSubmissions = maxNumberOfPetsArray
    .map((num) => {
      return {
        id: num,
        has_pet: stringToBool(
          generateSubmittedDataKey('has_pet', num) as string
        ),
        pet_type: generateSubmittedDataKey('cat_or_dog', num),
        name: generateSubmittedDataKey('pet_name', num),
        breed: generateSubmittedDataKey('pet_breed', num),
        age: getAgeFromString(
          generateSubmittedDataKey('pet_age', num) as AgeRange
        ),
        weight: getWeightFromString(
          generateSubmittedDataKey('pet_weight', num) as Weight
        ),
        photo_files: generateSubmittedDataKey('pet_photo', num),
        vax_files: generateSubmittedDataKey('pet_vax', num),
        is_esa: stringToBool(
          generateSubmittedDataKey('esa_animal', num) as string
        ),
        // this should never actually exist if there isn't a structured_submit since it's a new key
        // adding this here for edge-cases and consistency
        esa_files: generateSubmittedDataKey('esa_files', num),
      };
    })
    .filter((p) => p.has_pet);

  return structuredOldSubmissions as Array<PetsStructuredSubmit>;
};

export const loadSubmissionForInformant = (
  structuredSubmission: PetsStructuredSubmit
): PetsInformantSubmission => {
  const formatForInformant = {
    is_esa: boolToString(structuredSubmission?.is_esa),
  };
  return {
    ...structuredSubmission,
    ...formatForInformant,
  };
};

/**
 * 
 * ideal submit
 * 
 * [
 *   {
 *     hasPet: submission.has_pet?.value,
    petType: submission.cat_or_dog?.value,
    name: submission.pet_name?.value,
    breed: submission.pet_breed?.value,
    age: getAgeFromString(submission.pet_age?.value as AgeRange),
    weight: getWeightFromString(submission.pet_weight?.value as Weight),
    photoFiles: submission.pet_photo?.files || submission.pet_photo?.value,
    vaxFiles: submission.pet_vax?.files || submission.pet_vax?.value,
    isESA: submission.esa_animal?.value,
 * 
 * },
 * 
 *{
 *     hasPet: submission.has_pet?.value,
    petType: submission.cat_or_dog?.value,
    name: submission.pet_name?.value,
    breed: submission.pet_breed?.value,
    age: getAgeFromString(submission.pet_age?.value as AgeRange),
    weight: getWeightFromString(submission.pet_weight?.value as Weight),
    photoFiles: submission.pet_photo?.files || submission.pet_photo?.value,
    vaxFiles: submission.pet_vax?.files || submission.pet_vax?.value,
    isESA: submission.esa_animal?.value,}
 * 
 * ]
 */
