import { NextPage } from 'next';
import { ElementType } from 'react';

export type ConsumerAppNextPage<T = object> = NextPage<T> & {
  allowUnauthorized?: boolean;
  provider?: ElementType;
};

export type { AsyncResult, AsyncResultStatus } from './async-result';

export * from './item-kinds';
export * from './proof-file';
export * from './item-definition';
